import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import PrivacyPolicy from "./components/PrivacyPolicy";
import Share from "./components/Share";
import Home from "./components/Home";
//import * as serviceWorker from "./serviceWorker";
import { BrowserRouter, Route, Switch } from "react-router-dom";

ReactDOM.render(
  <BrowserRouter>
    <div>
      <Switch>
        <Route path="/privacypolicy" component={PrivacyPolicy} />
        <Route path="/privacy/policy" component={PrivacyPolicy} />
        <Route path="/pages/privacypolicy" component={PrivacyPolicy} />
        <Route path="/shop/:link" component={Share} />
        <Route path="/" component={Home} />
      </Switch>
    </div>
  </BrowserRouter>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
