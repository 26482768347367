import React, { Component } from "react";
import {
  Dialog,
  DialogActions,
  DialogButton,
  DialogContent,
  DialogTitle,
} from "@rmwc/dialog";
import "@material/dialog/dist/mdc.dialog.css";
import "@material/button/dist/mdc.button.css";
import "./Alert.css";

class Alert extends Component {
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={(evt) => {
            //get the function passed in props
            const alertFalse = this.props.setFalse;
            alertFalse();
          }}
        >
          <DialogTitle>Welcome!</DialogTitle>
          <DialogContent>{this.props.message}</DialogContent>
          <DialogActions>
            <DialogButton action="close">OK</DialogButton>
            <DialogButton
              action="accept"
              onClick={() => {
                window.location.href =
                  "https://play.google.com/store/apps/details?id=com.billon.air.billonair";
              }}
            >
              Download
            </DialogButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default Alert;
