import React, { Component } from "react";
import {
  Dialog,
  DialogActions,
  DialogButton,
  DialogContent,
  DialogTitle,
} from "@rmwc/dialog";
import "@material/dialog/dist/mdc.dialog.css";
import "@material/button/dist/mdc.button.css";
import "./ItemDialog.css";

class ItemDialog extends Component {
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={(evt) => {
            //get the function passed in props
            const showFalse = this.props.setFalse;
            showFalse();
          }}
        >
          <DialogTitle>{this.props.message.title}</DialogTitle>
          <DialogContent>
            {this.props.message.description}
            <br></br>
            {this.props.message.weight}
          </DialogContent>
          <DialogActions>
            <DialogButton action="close">
              {this.props.message.price}
            </DialogButton>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default ItemDialog;
