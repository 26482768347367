import React, { Component } from "react";
import { Link } from "react-router-dom";
import phone1 from "../Assets/images/user-01-web.png";
import phone2 from "../Assets/images/user-03-web.png";
import phone3 from "../Assets/images/user-02-web.png";
import shop from "../Assets/images/icons8-shop-96.png";
import binocular from "../Assets/images/icons8-binoculars-250.png";
import search from "../Assets/images/icons8-search-96.png";
import playStore from "../Assets/SVG/google-play-badge.svg";
import one from "../Assets/images/1.jpg";
import two from "../Assets/images/2.jpg";
import b2 from "../Assets/images/b2.png";
import three from "../Assets/images/3.jpg";
import four from "../Assets/images/4.jpg";
import five from "../Assets/images/5.png";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./Home.css";

class Home extends Component {
  render() {
    return (
      <div>
        <section className="gradient-banner">
          <div className="grdient-banner_container">
            <div className="gbc_left-part">
              <span className="main-head">BillOnAir</span>
              <span className="main-headline">#VocalForLocal</span>
              <span className="sub-headline">
                Shop online with your local shop in your locality only at
                BillOnAir
              </span>
              <button
                className="download-btn"
                onClick={() => {
                  window.location.href =
                    "https://play.google.com/store/apps/details?id=com.billon.air.billonair";
                }}
              >
                Download Now
              </button>
            </div>
            <div className="gbc_right-part">
              <img
                style={{ height: "70vh", alignSelf: "center" }}
                src={phone1}
                alt=""
              />
            </div>
          </div>
        </section>
        <section className="rounded-block">
          <div className="shapes-container"></div>
          <div className="feature-box a">
            <img className="feature-img" src={shop} alt="" />
            <span className="feature-heading">Shop Local</span>
            <span className="feature-subheading">
              Shop products from nearby trusted shops, easily contact shopkeeper
              for your queries
            </span>
          </div>
          <div className="feature-box b">
            <img className="feature-img" src={search} alt="" />
            <span className="feature-heading">Search Items</span>
            <span className="feature-subheading">
              Search for product in your locality easily
            </span>
          </div>
          <div className="feature-box c">
            <img className="feature-img" src={binocular} alt="" />
            <span className="feature-heading">Distance Management</span>
            <span className="feature-subheading">
              Set your distance for reach, convenience and ease of the local
              shop
            </span>
          </div>
        </section>
        <div className="business-section">
          <img className="business-section-img" src={phone2} alt="" />
          <div className="business-section-info">
            <h1 className="business-heading interface-heading">
              Discover best shops near you
            </h1>
            <span className="business-subheading">Shop in your locality</span>
            <p className="business-desc interface-subheading">
              <span>Get connected to the shops easily. </span>
              <br />
              <span>
                Find out your need around you and easily check their
                availability
              </span>
              <br />
              <span>No need to remember your previous order,</span>
              <br />
              <span>
                neither your selected locations.
                <br />
              </span>
            </p>
          </div>
        </div>
        <div className="information-section">
          <div className="information-section-info">
            <h1 className="information-heading interface-heading">
              Get shop and product details easily
            </h1>
            <span className="information-subheading">
              Check available products around you
            </span>
            <p className="information-desc interface-subheading">
              <span>
                Get detailed information of the shops quickly and contact them.
              </span>

              <span>Add items in your cart for order.</span>
              <br />
              <span>
                Share details of the shop and select your favorite one
              </span>
              <br />
              <span>
                and get directions to the shop using direction
                <br />
              </span>
            </p>
          </div>
          <img className="information-section-img" src={phone3} alt="" />
        </div>
        <div className="interface-section">
          <h2 className="interface-heading">
            An interface for local online shopping
          </h2>
          <span
            className="interface-subheading"
            style={{ marginBottom: "2rem" }}
          >
            Get to know about the products available at your nearby local
            shops..
          </span>
          <div className="image">
            <Carousel
              autoPlay={true}
              centerMode={true}
              showThumbs={false}
              showStatus={false}
              infiniteLoop={true}
              dynamicHeight={true}
              swipeable={true}
              style={{ backgroundColor: "white" }}
            >
              <div style={{ padding: "0.25rem", backgroundColor: "white" }}>
                <img src={one} alt="" />
              </div>
              <div style={{ padding: "0.25rem", backgroundColor: "white" }}>
                <img src={two} alt="" />
              </div>
              <div style={{ padding: "0.25rem", backgroundColor: "white" }}>
                <img src={three} alt="" />
              </div>
              <div style={{ padding: "0.25rem", backgroundColor: "white" }}>
                <img src={b2} alt="" />
              </div>
              <div style={{ padding: "0.25rem", backgroundColor: "white" }}>
                <img src={four} alt="" />
              </div>
              <div style={{ padding: "0.25rem", backgroundColor: "white" }}>
                <img src={five} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
        <div className="footer">
          <span className="footer-heading">Connect With Us</span>
          <div className="contacts">
            <div className="contact-card">
              <div className="footer-namecard">
                <span className="footer-name">BillOnAir Shop Partner</span>
                <br />
                <span className="footer-number"></span>
                <br />
              </div>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.billon.air.billonairshop">
                  <img className="footer-icon" src={playStore} alt="" />
                </a>
              </div>
            </div>
            <div className="contact-card">
              <div className="footer-namecard">
                <span className="footer-name">BillOnAir</span>
                <br />
                <span className="footer-number"></span>
                <br />
              </div>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.billon.air.billonair">
                  <img className="footer-icon" src={playStore} alt="" />
                </a>
              </div>
            </div>
            <div className="papersqueeze-card contact-card">
              <span className="footer-mail">support@billonair.com</span>
              <br />
              <span className="footer-address">
                CHEMBUR, MUMBAI
                <br />
                MAHARASHTRA 400071, INDIA.
              </span>
            </div>
          </div>
          <div className="copyright">
            <span style={{ color: "white" }}>
              © 2020 BillOnAir All rights reserved
            </span>
            <a href="">
              <span style={{ color: "white" }}>
                |<Link to="/pages/privacypolicy">Terms of Service</Link>
              </span>
            </a>
            <a href="">
              <span style={{ color: "white" }}>
                |<Link to="/pages/privacypolicy">Privacy policy</Link>
              </span>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
