import React, { Component } from "react";
import Alert from "./Alert";
import ItemDialog from "./ItemDialog";
import { Typography } from "@rmwc/typography";
import { Card } from "@rmwc/card";
import { List, CollapsibleList, SimpleListItem } from "@rmwc/list";
import "./Share.css";
import shopImage from "../Assets/images/1.jpg";
import store from "../Assets/images/shop.png";
import time from "../Assets/images/time.png";
import direction from "../Assets/images/direction.png";
import dots from "../Assets/images/menu.png";
import next from "../Assets/images/next.png";
import "@rmwc/typography/styles";
import "@rmwc/card/styles";
import "@rmwc/list/styles";

class Share extends Component {
  constructor() {
    super();
    //to pass the function as props to child
    this.setAlertFalse = this.setAlertFalse.bind(this);
    this.setItemDialgFalse = this.setItemDialgFalse.bind(this);
    this.state = {
      shop: {
        accept_order: true,
        available_open: true,
        close_time: "24:00:00",
        closed_on: "Monday",
        delivery: false,
        delivery_cost: null,
        delivery_dist: null,
        description: "One stop for all your needs #VocalForLocal",
        free_delivery_above: null,
        id: 0,
        latitude: 0.0,
        longitude: 0.0,
        open_time: "00:00:00",
        pick_up: true,
        profile: shopImage,
        ratings: null,
        share: "billonair",
        shop_category: "BillOnAir Shop",
        shop_menu: [],
        shop_name: "BillOnAir",
        status: "requested",
        upi_id: null,
      },
      alert: false,
      item_dialog: false,
      //item data has title,price,weight and description
      item_data: {},
    };
  }

  //to fetch the data of the share shop
  componentDidMount() {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token ae79004f7f5acb6bd71bed8a2399d94f822d01ac"
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch(
      "https://api.billonair.com/api/v1/shop/share/" +
        this.props.match.params.link +
        "/",
      requestOptions
    )
      .then((response) => response.json())
      .then((json) => {
        //if shop exists then json does'nt have detail in it
        if (!json.hasOwnProperty("detail"))
          this.setState({ shop: json, alert: true });
      });
  }

  //to set the shop profile image
  setProfile() {
    if (this.state.shop.profile === shopImage) return shopImage;
    return this.state.shop.profile;
  }

  //to set the description of items
  setDescription(description, weight) {
    if (description === null && weight !== null) return weight;
    else if (description !== null && weight === null) return description;
    else if (description !== null && weight !== null)
      return "(" + weight + ") " + description;
    else return "";
  }

  timeTo12HrFormat(time) {
    // Take a time in 24 hour format and format it in 12 hour format
    var time_part_array = time.split(":");
    var ampm = "AM";

    if (time_part_array[0] >= 12) {
      ampm = "PM";
    }

    if (time_part_array[0] > 12) {
      time_part_array[0] = time_part_array[0] - 12;
    }

    var formatted_time =
      time_part_array[0] + ":" + time_part_array[1] + " " + ampm;

    return formatted_time;
  }

  //to set open or closed for shop
  setTime() {
    var now = new Date();
    //make the current time in 24 hrs format
    var pretty = [
      now.getHours(),
      ":",
      now.getMinutes(),
      ":",
      now.getSeconds(),
    ].join("");
    //compare that current time is graeter than opening time and less than closing time
    if (
      pretty > this.state.shop.open_time &&
      pretty < this.state.shop.close_time
    )
      return (
        "Open. Closes at " + this.timeTo12HrFormat(this.state.shop.close_time)
      );
    return (
      "Closed. Opens at " + this.timeTo12HrFormat(this.state.shop.open_time)
    );
  }

  // to close the alert
  setAlertFalse() {
    this.setState({ alert: false });
  }

  //to close the item dialog
  setItemDialgFalse() {
    this.setState({ item_dialog: false });
  }

  //to show item dialog with item detail
  showItemDialog(title, price, weight, description) {
    this.setState({
      item_dialog: true,
      item_data: { title, price, weight, description },
    });
  }

  render() {
    return (
      <div className="container">
        <Alert
          open={this.state.alert}
          setFalse={this.setAlertFalse.bind(this)}
          message={`Download the BillOnAir App to order from ${this.state.shop.shop_name}`}
        />
        <ItemDialog
          open={this.state.item_dialog}
          setFalse={this.setItemDialgFalse.bind(this)}
          message={this.state.item_data}
        />
        <div className="shop-header">
          <img className="shop-image" src={this.setProfile()} alt="shop" />
          <Typography use="headline4" className="shop-name">
            {this.state.shop.shop_name}
          </Typography>
          <Typography use="headline6" className="shop-description">
            {this.state.shop.description}
          </Typography>
          <div className="shop-sub-description">
            <img className="shop-icon" src={store} alt="store" />
            <Typography
              use="subtitle1"
              style={{ alignSelf: "center", marginLeft: "1em" }}
            >
              {this.state.shop.shop_category}
            </Typography>
          </div>
          <div className="shop-sub-description">
            <img className="shop-icon" src={time} alt="time" />
            <Typography
              use="subtitle1"
              style={{ alignSelf: "center", marginLeft: "1em" }}
            >
              {this.setTime()}
            </Typography>
          </div>
          <div className="shop-sub-description">
            <img className="shop-icon" src={direction} alt="direction" />
            <a
              href={`https://www.google.com/maps/search/?api=1&query=${this.state.shop.latitude},${this.state.shop.longitude}`}
              style={{
                alignSelf: "center",
                marginLeft: "1em",
                textDecoration: "none",
                color: "inherit",
              }}
              target="_blank"
            >
              <Typography use="subtitle1">Direction</Typography>
            </a>
          </div>
          <Typography use="caption" className="billonair-tag">
            @BillOnAir
          </Typography>
        </div>
        <div className="shop-menu">
          {this.state.shop.shop_menu.map((men, id) => {
            return (
              <Card
                key={id}
                outlined
                style={{ width: "80%", alignSelf: "center", margin: "1rem" }}
              >
                <List twoLine>
                  <CollapsibleList
                    defaultOpen
                    handle={
                      <SimpleListItem
                        text={men.title}
                        graphic={dots}
                        secondaryText={men.menu_items.length + " items"}
                        metaIcon={next}
                      />
                    }
                  >
                    {men.menu_items.map((item, id) => {
                      return (
                        <SimpleListItem
                          key={id}
                          graphic={id + 1}
                          text={item.item_name}
                          secondaryText={this.setDescription(
                            item.description,
                            item.item_weight
                          )}
                          metaIcon={"₹" + item.price}
                          onClick={() => {
                            this.showItemDialog(
                              item.item_name,
                              "₹" + item.price,
                              item.item_weight || "",
                              item.description || ""
                            );
                          }}
                        />
                      );
                    })}
                  </CollapsibleList>
                </List>
              </Card>
            );
          })}
          <Typography use="caption" className="billonair-tag">
            @BillOnAir
          </Typography>
        </div>
      </div>
    );
  }
}

export default Share;
