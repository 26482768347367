import React from 'react'
import '../App.css';

function PrivacyPolicy() {
    return (
        <div class="privacy-maincontainer">
        <div class="privacy-head">Privacy policy</div>
        <div class="cont">
            <div class="privacy-prgh">
                We view protection of Your privacy as a very important principle. We understand clearly that You and Your Personal Information is one of Our most important assets. We store and process Your Information including any sensitive financial information collected
                (as defined under the Information Technology Act, 2000), if any, on computers that may be protected by physical as well as reasonable technological security measures and procedures in accordance with Information Technology Act 2000 and
                Rules there under. Our current Privacy Policy is available at <i style={{color: "blue"}}> https://www.billonair.com/pages/privacypolicy</i>. If You object to Your Information being transferred or used in this way please do
                not use Website.
            </div>
            <div class="privacy-prgh">
                We may disclose personal information to third parties. This disclosure may be required for us to provide you access to our Services, to comply with our legal obligations, to enforce our User Agreement, to facilitate our marketing and advertising activities,
                or to prevent, detect, mitigate, and investigate fraudulent or illegal activities related to our Services. We do not disclose your personal information to third parties for their marketing purposes without your explicit consent.
            </div>
            <div class="privacy-prgh">
                We may disclose personal information if required to do so by law or in the good faith belief that such disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may disclose personal information to law enforcement
                offices, third party rights owners, or others in the good faith belief that such disclosure is reasonably necessary to: enforce our Terms or Privacy Policy; respond to claims that an posting or other content violates the rights of a third
                party; or protect the rights, property or personal safety of our users or the general public.
            </div>
            <div class="privacy-prgh">
                We and our affiliates will share some or all of your personal information with another business entity should we (or our assets) plan to merge with, or be acquired by that business entity, or re-organization, amalgamation, restructuring of business. Should
                such a transaction occur that other business entity (or the new combined entity) will be required to follow this privacy policy with respect to your personal information.
            </div>
            <div class="privacy-prgh">
                Please read this policy carefully to understand BillOnAir policies and practices regarding your information and how BillOnAir will treat it. By accessing or using its Services and/or registering for an account with BillOnAir, you agree to this privacy
                policy and you are consenting to BillOnAir's collection, use, disclosure, retention, and protection of your personal information as described here. If you do not provide the information BillOnAir requires, BillOnAir may not be able to
                provide all of its Services to you.
            </div>
            <div class="privacy-prgh">
                Your data controller is responsible for the collection, use, disclosure, retention, and protection of your personal information in accordance with its privacy standards as well as any applicable national laws. Your data controller may transfer data to
                other members of BillOnAir as described in this Privacy Policy. BillOnAir may process and retain your personal information on its servers in India where its data centers are located, and/or on the servers of its third parties (in or outside
                India), having contractual relationships with BillOnAir
            </div>
            <div class="privacy-prgh-t">DISCLAIMER OF WARRANTIES AND LIABILITY :</div>
            <div class="privacy-prgh">
                This Website, all the Materials and information/content (including but not limited to software) and services, included on or otherwise made available to You through this website are provided on “as is” and “as available” basis without any representation
                or warranties, express or implied except otherwise specified in writing. Without prejudice to the forgoing paragraph,BillOnAir does not warrant that:
            </div>
            <div class="privacy-prgh">
                This Website will be constantly available, or available at all; or The information on this Website is complete, true, accurate or non-misleading.
            </div>
            <div class="privacy-prgh">
                BillOnAir will not be liable to You in any way or in relation to the Materials of, or use of, or otherwise in connection with, the Website. BillOnAir does not warrant that this site; information, content, Materials, product (including software) or services
                included on or otherwise made available to You through the Website; their servers; or electronic communication sent from Us are free of viruses or other harmful components.
            </div>
            <div class="privacy-prgh-t">LIMITATION OF DAMAGES :</div>
            <div class="privacy-prgh">
                In no event shall BillOnAir or any of its subsidiaries or affiliates be liable to any entity for any direct, indirect, special, consequential or other damages (including, without limitation, any lost profits, business interruption, loss of information
                or programs or other data on your information handling system) that are related to the use of, or the inability to use, the content, materials, and functions of this Website or any linked Website, even if BillOnAir is expressly advised
                of the possibility of such damages.
            </div>
        </div>
        </div>
    )
}

export default PrivacyPolicy
